<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(dayData/1000) | parseTime('{y}-{m}-{d}') }}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn active" @click="handleDatePicker()">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								value-format="timestamp"
								@change="changeDatePicker()">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.lives.overview')}}</div>
				<div class="barbox"></div>
			</div>
			<div class="sycm-overall">
				<div class="overall-top">
					<div class="item">
						<div class="name">
							<span>{{$t('sycm.lives.orderMoney')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.lives.orderMoney')+'”'}}</p>
									<p>{{$t('sycm.popover.liveOrderMoney1')}}</p>
								</div>
								<div class="popover-main">{{$t('sycm.popover.liveOrderMoney2')}}</div>
							</el-popover>
							<span @click="handleTrendShow(liveData.list,$t('sycm.lives.orderMoney'))"><svg-icon icon-class="broken_line"></svg-icon></span>
						</div>
						<div class="number">{{liveData.values}}</div>
					</div>
				</div>
				<div class="overall-tabs">
					<div class="item active">
						<div class="name">
							<span>{{$t('sycm.lives.storeOrderMoney')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.lives.storeOrderMoney')+'”'}}</p>
									<p>{{$t('sycm.popover.storeOrderMoney1')}}</p>
								</div>
								<div class="popover-main">{{$t('sycm.popover.storeOrderMoney2')}}</div>
							</el-popover>
							<span @click="handleTrendShow(storeData.list,$t('sycm.lives.storeOrderMoney'))"><svg-icon icon-class="broken_line"></svg-icon></span>
						</div>
						<div class="number">{{storeData.values}}</div>
					</div>
					<div class="item"></div>
				</div>
			</div>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisibleTrends" width="800px">
				<div class="dialog-chart">
					<sycm-chart id="chartOption" v-if="chartOption" :x-data="chartOption.xData" :s-data="chartOption.sData" :max-axis="chartOption.max.toString()" legend-left="0" height="100%" width="100%" />
				</div>
			</el-dialog>
			<div class="section-main">
				<el-table :data="crowdList">
					<el-table-column :label="$t('sycm.tableHead.crowd')" prop="type_name"></el-table-column>
					<el-table-column :label="$t('sycm.tableHead.liveVisitors')" align="right" prop="visitors">
						<template slot="header">
							<span>{{$t('sycm.tableHead.liveVisitors')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.tableHead.liveVisitors')+'”'}}</p>
									<p>{{$t('sycm.popover.liveVisitors1')}}</p>
								</div>
								<div class="popover-main">{{$t('sycm.popover.liveVisitors2')}}</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.dealNumber')" align="right" prop="buyers">
						<template slot="header">
							<span>{{$t('sycm.tableHead.dealNumber')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.tableHead.dealNumber')+'”'}}</p>
									<p>{{$t('sycm.popover.liveDealNumber1')}}</p>
								</div>
								<div class="popover-main">{{$t('sycm.popover.liveDealNumber2')}}</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.dealMoney')" align="right" prop="amount">
						<template slot="header">
							<span>{{$t('sycm.tableHead.dealMoney')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.tableHead.dealMoney')+'”'}}</p>
									<p>{{$t('sycm.popover.liveDealMoney1')}}</p>
								</div>
								<div class="popover-main">{{$t('sycm.popover.liveDealMoney2')}}</div>
							</el-popover>
						</template>
						<template slot-scope="scope">{{scope.row.amount | priceFilter()}}</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import sycmChart from '@/components/echart/ipollChart'
import { fetchLivesOverview, fetchLivesCrowd } from "@/api/sycm"
export default {
	components: { sycmChart },
	data() {
		return {
			dayData: Date.now() - 86400000,
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			liveData: {},
			storeData: {},
			dialogTitle: '',
			dialogVisibleTrends: false,
			chartOption: null,
			crowdList: []
		}
	},
	created () {
		this.getData()	
	},
	methods: {
		async getData() {
			const loading = this.$loading()
			try {
				const params = {
					time_from: parseInt(this.dayData / 1000),
					time_to: parseInt(this.dayData / 1000),
					date_type: this.dateType
				}
				const overview = await fetchLivesOverview(params)
				this.liveData = overview.data.live
				this.storeData = overview.data.store
				const crowd = await fetchLivesCrowd(params)
				this.crowdList = crowd.data
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleDatePicker() {
			this.$refs.dayPicker.focus()
		},
		changeDatePicker(val) {
			this.getData()
		},
		handleTrendShow(list,name) {
			this.dialogTitle = name + this.$t('sycm.common.trend')
			this.chartOption = null
			const xData = []
			const todayData = []
			var max = 0
			list.forEach(item=> {
				xData.push(item.x_values)
				todayData.push(item.values)
				if(item.values > max) {
					max = item.values
				}
			})
			const options = {
				max: max ? max : 4,
				xData: xData,
				sData: [{
					name: name,
					data: todayData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				}]
			}
			this.$nextTick(()=>{
				this.chartOption = options
				this.dialogVisibleTrends = true
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				.item-link {
					color: $--sycm-primary;
					cursor: pointer;
					display: inline-block;

					span {
						margin-right: 5px;
					}

					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;

			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-map {
		display: flex;
		justify-content: space-between;
		.map-chart {
			width: 500px;
			height: 375px;
		}
		.map-rank {
			width: 370px;
			padding-top: 30px;
			font-size: 12px;
			line-height: 1.5;
			.rank-table {
				th {
					color: $--sycm-primary;
				}
				th,td {
					padding: 4px;
				}
			}
			.rank-bar {
				height: 16px;
				width: 100%;
				background-color: $--sycm-primary;
			}
		}
	}
	.sycm-overall {
		.overall-top {
			height: 206px;
			background: linear-gradient(180deg,rgba(238,247,255,.5) 100%,hsla(0,0%,100%,.5) 0);
			.item {
				color: #333333;
				padding: 30px 46px;
				.name {
					font-size: 18px;
					font-weight: 600;
					span {
						margin-right: 10px;
					}
					.svg-icon {
						cursor: pointer;
						color: $--sycm-primary;
					}
				}
				.number {
					margin-top: 14px;
					font-size: 32px;
				}
			}
		}
		.overall-tabs {
			margin-top: -76px;
			padding: 0 16px;
			height: 106px;
			display: flex;
			.item {
				flex-shrink: 0;
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: center;
				flex-flow: column;
				background-color: #f7f9fc;
				border: 1px solid #cacaca;
				padding-left: 20px;
				box-sizing: border-box;
				position: relative;
				.name {
					font-size: 18px;
					font-weight: 600;
					span {
						margin-right: 10px;
					}
					.svg-icon {
						cursor: pointer;
						color: $--sycm-primary;
					}
				}
				.number {
					margin-top: 14px;
					font-size: 32px;
				}
				&:first-child {
					&:before {
						content: "";
						left: -16px;
						bottom: -1px;
						width: 16px;
						height: 1px;
						background-color: #cacaca;
						position: absolute;
					}
				}
				&:last-child {
					&:after {
						content: "";
						right: -16px;
						bottom: -1px;
						width: 16px;
						height: 1px;
						background-color: #cacaca;
						position: absolute;
					}
				}
				&.active {
					background-color: #ffffff;
					border-top-width: 6px;
					border-color: $--sycm-primary;
					border-bottom-color: transparent;
					&:before {
						background-color: $--sycm-primary;
					}
					&:after {
						background-color: $--sycm-primary;
					}
				}
			}
		}
	}
}
.dialog-chart {
	height: 300px;
}
</style>